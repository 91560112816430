import React from "react";
import { Link } from "react-router-dom";

const ActionButton = ({
  icon,
  link_color,
  click_action = null,
  text = "",
  href = null,
  spa_mode = false,
}) => {
  if (spa_mode == false) {
    return (
      <a
        href={href}
        rel="noopener noreferrer"
        onClick={click_action}
        className={!text ? "rounded-circle" : ""}
        style={
          !text
            ? {
                width: "24px",
                height: "24px",
                background: "#f5f5f5",
                cursor: "pointer",
                color: link_color,
                marginRight: 2,
                marginLeft: 2,
                display: "inline-block",
              }
            : {
                background: "#f5f5f5",
                cursor: "pointer",
                color: link_color,
                display: "inline-block",
                marginRight: 2,
                marginLeft: 2,
                borderRadius: "8px",
                padding: "0px 8px",
              }
        }
      >
        {!text && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "4px",
            }}
          >
            {icon}
          </div>
        )}
        {text && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: "5px",
              padding: "1px 0",
              verticalAlign: "middle",
            }}
          >
            <div style={{ paddingBottom: "2px" }}>{icon}</div>
            <div>{text}</div>
          </div>
        )}
      </a>
    );
  } else {
    return (
      <Link
        to={href}
        onClick={click_action}
        className={!text ? "rounded-circle" : ""}
        style={
          !text
            ? {
                width: "24px",
                height: "24px",
                background: "#f5f5f5",
                cursor: "pointer",
                color: link_color,
                marginRight: 2,
                marginLeft: 2,
                display: "inline-block",
              }
            : {
                background: "#f5f5f5",
                cursor: "pointer",
                color: link_color,
                display: "inline-block",
                marginRight: 2,
                marginLeft: 2,
                borderRadius: "8px",
                padding: "0px 8px",
              }
        }
      >
        {!text && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "4px",
            }}
          >
            {icon}
          </div>
        )}
        {text && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: "5px",
              padding: "1px 0",
              verticalAlign: "middle",
            }}
          >
            <div style={{ paddingBottom: "2px" }}>{icon}</div>
            <div>{text}</div>
          </div>
        )}
      </Link>
    );
  }
};

export default ActionButton;
