import axios from "axios";
import { handleResponse } from "./HelperService";

const getAllOverseers = (params, onSuccess, onError) => {
  const response = axios.get("v1/overseer", { params });
  return handleResponse(response, onSuccess, onError);
};

const createOverseer = (payload, onSuccess, onError) => {
  const response = axios.post("v1/overseer", payload);
  return handleResponse(response, onSuccess, onError);
};

const deleteOverseer = (id, company_id, onSuccess, onError) => {
  const response = axios.delete(`v1/overseer/${id}/${company_id}`);
  return handleResponse(response, onSuccess, onError);
};

export { getAllOverseers, createOverseer, deleteOverseer };
