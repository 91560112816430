import axios from "axios";
import { handleResponse } from "./HelperService";

const getAllRequesters = (params, onSuccess, onError) => {
  const response = axios.get("v1/requester", { params });
  return handleResponse(response, onSuccess, onError);
};

const createRequester = (payload, onSuccess, onError) => {
  const response = axios.post("v1/requester", payload);
  return handleResponse(response, onSuccess, onError);
};

const deleteRequester = (id, company_id, onSuccess, onError) => {
  const response = axios.delete(`v1/requester/${id}/${company_id}`);
  return handleResponse(response, onSuccess, onError);
};

export { getAllRequesters, createRequester, deleteRequester };
