import axios from "axios";
import { handleResponse } from "./HelperService";

const getAllAdministrators = (params, onSuccess, onError) => {
  const response = axios.get("v1/administrator", { params });
  return handleResponse(response, onSuccess, onError);
};

const createAdministrator = (payload, onSuccess, onError) => {
  const response = axios.post("v1/administrator", payload);
  return handleResponse(response, onSuccess, onError);
};

const deleteAdministrator = (id, company_id, onSuccess, onError) => {
  const response = axios.delete(`v1/administrator/${id}/${company_id}`);
  return handleResponse(response, onSuccess, onError);
};

export { getAllAdministrators, createAdministrator, deleteAdministrator };
