export const navData = [
  {
    id: 1,
    icon: <i className="fas fa-home" style={{ fontSize: "1rem" }} />,
    text: "Home",
    permission: "dashboard",
    link: "/",
  },
  {
    id: 2,
    icon: <i className="fas fa-home" style={{ fontSize: "1rem" }} />,
    text: "Home",
    permission: "requester-home",
    link: "/requester-home",
  },
  {
    id: 3,
    icon: (
      <i
        className="fa-solid fa-circle-exclamation"
        style={{ fontSize: "1.1rem" }}
      />
    ),
    text: "Incidents",
    link: "incidents",
    permission: "incidents",
    company: [1, 3],
  },
  {
    id: 7,
    icon: (
      <i
        className="fa-solid fa-screwdriver-wrench"
        style={{ fontSize: "1.1rem" }}
      />
    ),
    text: "Service Requests",
    permission: "services",
    link: "/service-requests",
  },
  {
    id: 661,
    icon: (
      <i
        className="fa-solid fa-file-circle-check"
        style={{ fontSize: "1.1rem" }}
      />
    ),
    text: "My Tickets",
    permission: "my-tickets",
    link: "/my-tickets",
  },
  {
    id: 667,
    icon: (
      <i className="fa-solid fa-book-reader" style={{ fontSize: "1.1rem" }} />
    ),
    text: "FAQs",
    permission: "faqs",
    link: "/knowledge-base",
  },
  {
    id: 66,
    icon: (
      <i
        className="fa-solid fa-person-walking-arrow-right"
        style={{ fontSize: "1.1rem" }}
      />
    ),
    text: "Out of Office",
    link: "out-of-office",
    permission: "ooo",
  },
  {
    id: 3,
    icon: <i className="fa-solid fa-gears" style={{ fontSize: "1.1rem" }} />,
    text: "Configuration Setting",
    permission: "settings",
    child: [
      {
        id: 4,
        text: "User Management",
        link: "user-settings",
      },
      {
        id: 5,
        text: "Group Management",
        link: "group-settings",
      },
      {
        id: 26,
        text: "SLA Management",
        link: "sla-settings",
      },
      {
        id: 15,
        text: "Business Hours",
        link: "business-hours",
      },
      {
        id: 9,
        text: "Categories",
        link: "category-settings",
      },
      {
        id: 16,
        text: "Service Request Fields",
        link: "service-request-fields",
      },
    ],
  },
];

export const guestNavData = [
  {
    id: 0,
    icon: <i className="fas fa-home" style={{ fontSize: "1rem" }} />,
    text: "Home",
    link: "/",
  },
  {
    id: 1,
    icon: (
      <i
        className="fa-solid fa-circle-exclamation"
        style={{ fontSize: "1.1rem" }}
      />
    ),
    text: "Incidents",
    link: "incidents",
    /*child: [
                        {
                                id: 3,
                                text: "Vendor/Supplier List",
                                link: "vendor",
                        },
                        {
                                        id: 3,
                                        text: "Selection",
                                        link: "settings",
                        },
                        {
                                        id: 3,
                                        text: "Evaluation",
                                        link: "settings",
                                },
                        {
                                        id: 3,
                                        text: "Purchase Order",
                                        link: "vendor-purchase",
                        },
                        {
                                        id: 3,
                                        text: "Goods Receive",
                                        link: "good-receives",
                                },
                        {
                                        id: 3,
                                        text: "AP Invoice",
                                        link: "settings",
                        },
                        {
                                        id: 3,
                                        text: "Outgoing Payment",
                                        link: "settings",
                        }
                ]*/
  },
];
