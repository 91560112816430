import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import {
  isObjectEmpty,
  permissionCheck,
  truncateToEllipsis,
} from "../../Utils/Utils";
import { useNavigate } from "react-router-dom";
import { Bar, Pie } from "react-chartjs-2";
import { logoutUser } from "../../Service/UserService";
import {
  getDashboardData,
  getDashboardOverview,
  getProductivityReport,
} from "../../Service/DashboardService";
import ServiceCategories from "../Pages/Settings/CategoryContents";
import IncidentCategories from "../Incident/CategoryContents";

import DashboardCard from "../../Components/DashboardCard";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { newtickets, overdue, pending, unresolved } from "../../Images";
import { capitalize } from "lodash";
import { getAll } from "../../Service/IncidentService";
import moment from "moment";
import MTable from "../../Components/MTable/MTable";
import ActionButton from "../../Components/MTable/ActionButton";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ArcElement,
  Tooltip,
  Legend
);

const { $ } = window;
function HomePage() {
  const navigate = useNavigate();
  const tableRef = useRef(null);
  let { userInfo } = useSelector((state) => state.auth);
  const [selected_category, setSelectedCategory] = useState({});
  const [selected_type, setSelectedType] = useState("");
  const [table_data, setTableData] = useState([]);
  const [export_params, setExportParams] = useState({});
  const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);
  const [isIncidentModalOpen, setIsIncidentModalOpen] = useState(false);
  const [export_datepicker_object, setExportDatepickerObject] =
    useState(undefined);

  const [top_cards_state, setTopCardsState] = useState({
    pending: 0,
    new: 0,
    unresolved: 0,
    overdue: 0,
  });

  const [new_tickets, setNewTickets] = useState([]);
  const [this_week_resolved, setThisWeekResolved] = useState([]);
  const [overdue_tickets, setOverdueTickets] = useState([]);
  const [top_level_data, setTopLevelData] = useState({
    new_tickets: [],
    overdue_tickets: [],
    unresolved_tickets: [],
    pending_tickets: [],
  });
  const [top_level_title, setTopLevelTitle] = useState("");
  const [top_level_modal_data, setTopLevelModalData] = useState([]);

  const [achievement_data, setAchievementData] = useState({
    labels: ["Resolve SLA Achieved", "Resolve SLA Undone"],
    datasets: [
      {
        data: [12, 19],
        backgroundColor: ["rgba(54, 162, 235, 0.2)", "rgba(75, 192, 192, 0.2)"],
        borderColor: ["rgba(54, 162, 235, 1)", "rgba(75, 192, 192, 1)"],
        borderWidth: 1,
      },
    ],
  });
  const [incident_data, setIncidentData] = useState({
    labels: [
      "Category A",
      "Category B",
      "Category C",
      "Category D",
      "Category E",
    ],
    datasets: [
      {
        label: "Top 5 Incident Categories",
        data: [40, 25, 15, 30, 48], // Provide your data values here
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)", // Color for Category A
          "rgba(54, 162, 235, 0.6)", // Color for Category B
          "rgba(75, 192, 192, 0.6)", // Color for Category C
          "rgba(255, 206, 86, 0.6)",
          "rgba(0, 0, 0, 0.6)", // Color for Category D
        ], // Customize the bar color
      },
    ],
  });

  const [request_data, setRequestData] = useState({
    labels: [
      "Category A",
      "Category B",
      "Category C",
      "Category D",
      "Category E",
    ],
    datasets: [
      {
        data: [40, 25, 15, 30, 48], // Provide your data values here
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)", // Color for Category A
          "rgba(54, 162, 235, 0.6)", // Color for Category B
          "rgba(75, 192, 192, 0.6)", // Color for Category C
          "rgba(255, 206, 86, 0.6)",
          "rgba(0, 0, 0, 0.6)", // Color for Category D
        ], // Customize the bar color
      },
    ],
  });

  const [showProductivityModal, setShowProductivityModal] = useState(false);
  const [show_incident_chart, setShowIncidentChart] = useState(false);
  const selectRef = useRef(null);
  const [dashboard_modal_type, setDashboardModalType] = useState("");
  const [showTopLevelModal, setShowTopLevelModal] = useState(false);
  const [show_request_chart, setShowRequestChart] = useState(false);
  const [show_achievement_chart, setShowAchievementChart] = useState(true);
  const incident_options = {
    plugins: {
      legend: {
        display: false, // Turn off the labels for datasets
      },
    },
    scales: {
      x: {
        reverse: true, // Reverse the x-axis
        ticks: {
          callback: function (value, index, values) {
            // Custom label rendering function that wraps long labels
            const maxLength = 10; // Set the maximum label length
            return incident_data?.labels[index].length > maxLength
              ? incident_data?.labels[index].substring(0, maxLength) + "..."
              : incident_data?.labels[index];
          },
        },
      },
      y: {
        beginAtZero: true, // Start y-axis at zero
      },
    },
    responsive: true,
  };

  const achievement_options = {
    maintainAspectRatio: false,
    responsive: true,
    height: 257,
  };

  const request_options = {
    plugins: {
      legend: {
        display: false, // Turn off the labels for datasets
      },
    },
    scales: {
      x: {
        reverse: true, // Reverse the x-axis
        ticks: {
          callback: function (value, index, values) {
            // Custom label rendering function that wraps long labels
            const maxLength = 10; // Set the maximum label length
            return request_data.labels[index].length > maxLength
              ? request_data.labels[index].substring(0, maxLength) + "..."
              : request_data.labels[index];
          },
        },
      },
      y: {
        beginAtZero: true, // Start y-axis at zero
      },
    },
    responsive: true,
  };

  useEffect(() => {
    if (userInfo && userInfo?.role_name) {
      if (!permissionCheck(userInfo, "dashboard", "view")) {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "You're not allowed to access that page!",
        });
        navigate("/requester-home");
      }

      getDashboardData(localStorage.getItem("company_id")).then((res) => {
        if (res.status == 200) {
          setTopCardsState({
            pending: res.data.total_pending || 0,
            unresolved: res.data.total_unresolved || 0,
            new: res.data.total_new || 0,
            overdue: res.data.total_overdue || 0,
          });

          let data_incident = { ...incident_data };
          data_incident.labels = res.data?.top5s?.incident?.categories || 0;
          data_incident.datasets[0].data = res.data?.top5s?.incident?.data || 0;

          setTopLevelData({
            new_tickets: res.data?.top_level_new_tickets,
            overdue_tickets: res.data?.top_level_overdue_tickets,
            unresolved_tickets: res.data.top_level_unresolved_tickets,
            pending_tickets: res.data?.top_level_pending_tickets,
          });

          setIncidentData(data_incident);
          setShowIncidentChart(true);

          let data_request = { ...request_data };
          data_request.labels = res.data?.top5s?.service_request?.categories;
          data_request.datasets[0].data =
            res.data?.top5s?.service_request?.data;

          setRequestData(data_request);
          setShowRequestChart(true);

          setThisWeekResolved(res.data.this_week_resolved);
          setNewTickets(res.data.new_tickets);
          setOverdueTickets(res.data.overdue_tickets);
          if (res.data.current_achievement) {
            let achievement_numbers = [
              res.data.current_achievement[0].total_ticket || 0,
              res.data.current_achievement[1].total_ticket || 0,
            ];
            setAchievementData({
              ...achievement_data,
              datasets: [
                {
                  data: achievement_numbers,
                  backgroundColor: [
                    "rgba(54, 162, 235, 0.2)",
                    "rgba(75, 192, 192, 0.2)",
                  ],
                  borderColor: [
                    "rgba(54, 162, 235, 1)",
                    "rgba(75, 192, 192, 1)",
                  ],
                  borderWidth: 1,
                },
              ],
            });
          }
        }
      });
    } else {
      logoutUser(userInfo?.role_name);
    }
  }, []);

  const onCloseTopLevelModal = (e) => {
    e.preventDefault();
    setTopLevelModalData([]);
    setShowTopLevelModal(false);
  };

  const onShowTopLevelModal = async (title, type) => {
    setDashboardModalType(type);
    setTopLevelTitle(title);
    tableRef.current?.refresh();
    setShowTopLevelModal(true);
  };

  const onCloseServiceCategory = (e) => {
    e.preventDefault();
    setSelectedCategory({});
    setIsServiceModalOpen(false);
  };

  const onCloseIncidentCategory = (e) => {
    e.preventDefault();
    setSelectedCategory({});
    setIsIncidentModalOpen(false);
  };

  const downloadProductivityReport = () => {
    /*if (!filter_date_by) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select filter!",
      });

      return;
    }

    if (filter_date_by && !filter_from_date) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select from date!",
      });

      return;
    }

    if (filter_date_by && !filter_to_date) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select to date!",
      });

      return;
    }

    let args = {
      filter_from_date: moment(filter_from_date).format("YYYY-MM-DD"),
      filter_to_date: moment(filter_to_date).format("YYYY-MM-DD"),
      filter_date_by,
      type: "incident",
      company_id: localStorage.getItem("company_id"),
    };
    if (selected_export_fields && !isObjectEmpty(selected_export_fields)) {
      let result = [];
      Object.keys(selected_export_fields).forEach((item) => {
        result.push(item);
      });

      args.selected_fields = result;
    }*/
    getProductivityReport({
      month: export_params.month,
      year: export_params.year,
      company_id: localStorage.getItem("company_id"),
      mode: "business_hour",
    }).then((res) => {
      if (res.status == 200) {
        if (!res.data.file) {
          Swal.fire({
            icon: "info",
            title: "No report found",
            text: "Please pick another month and year",
          });

          return;
        }
        const link = document.createElement("a");
        link.href = `${process.env.REACT_APP_IMAGE_URL}${process.env.REACT_APP_SFTP_REPORT_FOLDER}${res.data.file}`;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  const columns = [
    { title: "Ticket Number", field: "ticket_number" },
    { title: "Topic", render: (item) => item.subject || item.category_name },
    { title: "Requested For", field: "requested_for" },
    {
      id: 6,
      title: "Action",
      style: { width: "10%" },
      render: (item) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <ActionButton
              icon={<i className="fa fa-eye" style={{ fontSize: "0.7rem" }} />}
              link_color="#0099C3"
              click_action={(e) => {
                onCloseTopLevelModal(e);
                if (item.type == "incident") {
                  navigate(`/incident/${item.ticket_id}`);
                } else {
                  navigate(`/service-request/${item.ticket_id}`);
                }
              }}
              text="View"
            />
            {/*<ActionButton icon={<MdOutlineModeEdit/>} link_color="#0099C3"/>*/}
            {/*<ActionButton icon={<BsTrashFill/>} link_color="#FF4833" click_action={(e) => onRemove(e, item.id)}/>*/}
          </div>
        );
      },
    },
  ];

  const getCardTableData = (type) => {
    if (type) {
      return (params) => {
        return getDashboardOverview(
          type,
          localStorage.getItem("company_id"),
          params
        );
      };
    } else {
      return null;
    }
  };

  const propsTable = {
    columns,
    showPaginationCounter: true,
    getData: getCardTableData(dashboard_modal_type),
    maxTableHeight: "600px",
    showIndex: false,
    hideFilter: true,
    showAddButton: false,
    order: "requested_date",
    tableId: "table-incident",
  };

  const onShowServiceCategoryModal = () => {
    setIsServiceModalOpen(true);
  };

  const onShowIncidentCategoryModal = () => {
    setIsIncidentModalOpen(true);
  };

  const onSetCategory = (category_obj) => {
    setSelectedCategory(category_obj);
    setIsServiceModalOpen(false);
    setIsIncidentModalOpen(false);
  };

  useEffect(() => {
    if (selected_type == "service-request") {
      onShowServiceCategoryModal();
    } else if (selected_type == "incident") {
      onShowIncidentCategoryModal();
    }
  }, [selected_type]);

  useEffect(() => {
    let category_name =
      selected_category.name ?? selected_category.category_name;
    if (selected_type == "incident") {
      getAll({ filter: `category_name:${category_name}`, perpage: 3 }).then(
        (res) => {
          if (res.data.status == 200) {
            setTableData(res.data.data);
            selectRef.current.value = "";
            setSelectedType("");
          }
        }
      );
    } else if (selected_type == "service-request") {
      getAll({ filter: `category_name:${category_name}`, perpage: 3 }).then(
        (res) => {
          if (res.data.status == 200) {
            setTableData(res.data.data);
            selectRef.current.value = "";
            setSelectedType("");
          }
        }
      );
    }
  }, [selected_category]);

  return (
    <div className="content-wrapper">
      <section className="content" style={{ background: "#FAFAFA" }}>
        {showProductivityModal && (
          <div
            class="modal show right"
            id="modal-productivity"
            tabindex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div
              class="modal-dialog"
              role="document"
              style={{ maxHeight: "100vh", height: "100vh" }}
            >
              <div class="modal-content" style={{ height: "100vh" }}>
                <div className="modal-header">
                  <h5 className="modal-title black">
                    Export Productivity Data
                  </h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowProductivityModal(false)}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div>
                    <>
                      <div
                        className="ticket-date-filter"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "5px",
                        }}
                      >
                        <div className="bold black">
                          Select Timerange for Export:
                        </div>
                        <DatePicker
                          onChange={(time) => {
                            const month = time.getMonth() + 1; // getMonth() returns
                            const year = time.getFullYear();
                            setExportParams({ ...export_params, month, year });
                            setExportDatepickerObject(time);
                          }}
                          selected={export_datepicker_object}
                          autoComplete="off"
                          dateFormat="MMMM yyyy"
                          showMonthYearPicker
                          placeholderText="&#128197; Select"
                          className="servicedesk-productivity-export"
                        />
                      </div>
                    </>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-outline-dark"
                    type="button"
                    style={{ width: 150 }}
                    onClick={() => setShowProductivityModal(false)}
                  >
                    <i className="fa fa-times"></i> Close
                  </button>

                  <button
                    className="btn"
                    type="button"
                    style={{
                      width: 150,
                      background: "#3576C2",
                      color: "white",
                    }}
                    onClick={() => downloadProductivityReport()}
                  >
                    <i className="fa fa-check"></i> Export
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="container-fluid" style={{ background: "white" }}>
          {showTopLevelModal && (
            <div
              id="modal-top-level"
              className="modal show"
              style={{ display: "block" }}
            >
              <div className="modal-dialog modal-lg" style={{ maxWidth: 1000 }}>
                <div className="modal-content" style={{ width: "120%" }}>
                  <div className="modal-header">
                    <h5 className="modal-title black bold">
                      {top_level_title}
                    </h5>
                    <button
                      type="button"
                      className="close"
                      onClick={onCloseTopLevelModal}
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <MTable {...propsTable} ref={tableRef}></MTable>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isServiceModalOpen == true && (
            <div
              id="modal-service-category"
              className="modal show"
              style={{ display: "block" }}
              tabIndex="-1"
            >
              <div className="modal-dialog modal-lg" style={{ maxWidth: 1000 }}>
                <div className="modal-content" style={{ width: "120%" }}>
                  <div className="modal-header">
                    <h5 className="modal-title">Select Service Category</h5>
                    <button
                      type="button"
                      className="close"
                      onClick={onCloseServiceCategory}
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <ServiceCategories
                      sendCategoryToParent={onSetCategory}
                      all_categories_included={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {isIncidentModalOpen == true && (
            <div
              id="modal-incident-category"
              className="modal fade show"
              style={{ display: "block" }}
            >
              <div className="modal-dialog modal-lg" style={{ maxWidth: 1000 }}>
                <div className="modal-content" style={{ width: "120%" }}>
                  <div className="modal-header">
                    <h5 className="modal-title">Select Incident Category</h5>
                    <button
                      type="button"
                      className="close"
                      onClick={onCloseIncidentCategory}
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <IncidentCategories sendCategoryToParent={onSetCategory} />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div>
            <div
              className="card"
              style={{ padding: "30px", marginBottom: "40px" }}
            >
              <div className="row">
                <div className="col-lg-12" style={{ paddingBottom: "20px" }}>
                  <div className="row">
                    <div className="col-md-6">
                      <h4 className="fw-500 color-black43">Your Dashboard</h4>
                      <h6>A brief about the current state of operations</h6>
                    </div>
                    {
                      <div className="col-md-6">
                        <button
                          type="button"
                          class="btn btn-export btn-outline-modena right"
                          style={{ padding: "0.5em 1em", margin: "0 5px" }}
                          onClick={() => setShowProductivityModal(true)}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div>
                              <i className="fa-solid fa-download" />
                            </div>
                            <div style={{ marginLeft: "10px" }}>
                              Get Productivity Data
                            </div>
                          </div>
                        </button>
                      </div>
                    }
                  </div>
                </div>
              </div>
              {/*<div className='row'>
                            <div className='col-md-12 mh-2 d-flex'>
                                <button type="button" class="btn btn-outline-success db-button">12 Months</button>
                                <button type="button" class="btn btn-outline-success db-button">6 Months</button>
                                <button type="button" class="btn btn-outline-success db-button">5 Months</button>
                                <button type="button" class="btn btn-outline-success db-button">4 Months</button>
                                <button type="button" class="btn btn-outline-success db-button">3 Months</button>
                                <button type="button" class="btn btn-outline-success db-button">2 Months</button>
                            </div>
    </div>*/}
              <div className="row">
                <div className="col-xl-3 col-md-3 mb-3">
                  <div
                    className="card  h-100 py-2"
                    style={{ border: "1.5px solid #C4C5C4" }}
                  >
                    <DashboardCard
                      cursor_type="pointer"
                      onClickFunc={() =>
                        onShowTopLevelModal("New Tickets", "new")
                      }
                      card_title="New Tickets"
                      stats={top_cards_state.new}
                      explanation_text={
                        "Incoming incidents and service requests"
                      }
                      icon={newtickets}
                      icon_width={300}
                    />
                  </div>
                </div>
                <div className="col-xl-3 col-md-3 mb-3">
                  <div
                    className="card  h-100 py-2"
                    style={{ border: "1.5px solid #C4C5C4" }}
                  >
                    <DashboardCard
                      cursor_type="pointer"
                      onClickFunc={() =>
                        onShowTopLevelModal("Pending Tickets", "pending")
                      }
                      card_title="Pending Tickets"
                      stats={top_cards_state.pending}
                      explanation_text={
                        "Tickets needing third-party interventions"
                      }
                      icon={pending}
                      icon_width={300}
                    />
                  </div>
                </div>

                <div className="col-xl-3 col-md-3 mb-3">
                  <div
                    className="card  h-100 py-2"
                    style={{ border: "1.5px solid #C4C5C4" }}
                  >
                    <DashboardCard
                      cursor_type="pointer"
                      onClickFunc={() =>
                        onShowTopLevelModal("Unresolved Tickets", "unresolved")
                      }
                      card_title="Unresolved Tickets"
                      stats={top_cards_state.unresolved}
                      explanation_text={
                        "Incidents or requests not resolved/closed yet"
                      }
                      icon={unresolved}
                      icon_width={300}
                    />
                  </div>
                </div>
                <div className="col-xl-3 col-md-3 mb-3">
                  <div
                    className="card  h-100 py-2"
                    style={{ border: "1.5px solid #C4C5C4" }}
                  >
                    <DashboardCard
                      cursor_type="pointer"
                      onClickFunc={() =>
                        onShowTopLevelModal("Overdue Tickets", "overdue")
                      }
                      card_title="Overdue Tickets"
                      stats={top_cards_state.overdue}
                      explanation_text={
                        "Incidents or requests still needing help from agents"
                      }
                      icon={overdue}
                      icon_width={300}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="card mb-4" style={{ border: "none" }}>
                <div className="card-header d-flex flex-row align-items-center justify-content-between svc-card-header">
                  <h5 className="m-0 fw-500 color-black43">New Tickets</h5>
                  {/*<div className="dropdown no-arrow">
                                        <select name='ppn' className='form-control ps-0'>
                                            <option>Select One</option>
                                        </select>
</div>*/}
                </div>
                <div className="card-body">
                  <div>
                    <table
                      className="table table-condensed"
                      style={{
                        borderRadius: 0,
                        marginBottom: 0,
                        padding: "0.4rem",
                        fontSize: "16px",
                      }}
                    >
                      <thead>
                        <tr>
                          <th className="b2b-th">No</th>
                          <th className="b2b-th">Title</th>
                          <th className="b2b-th">Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {new_tickets?.map((item, index) => {
                          let to =
                            item.type == "incident"
                              ? "/incident/"
                              : "/service-request/";
                          return (
                            <tr
                              className="tr-dashboard"
                              onClick={() => navigate(`${to}${item.ticket_id}`)}
                            >
                              <td>{index + 1}</td>
                              <td>{truncateToEllipsis(item.title)}</td>
                              <td>{capitalize(item.type)}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card mb-4" style={{ border: "none" }}>
                <div className="card-header d-flex flex-row align-items-center justify-content-between svc-card-header">
                  <h5 className="m-0 fw-500 color-black43">
                    Resolved Tickets This Week
                  </h5>
                </div>
                <div className="card-body">
                  <div>
                    <table
                      className="table table-condensed"
                      style={{
                        borderRadius: 0,
                        marginBottom: 0,
                        padding: "0.4rem",
                        fontSize: "16px",
                      }}
                    >
                      <thead>
                        <tr>
                          <th className="b2b-th">No</th>
                          <th className="b2b-th">Ticket</th>
                          <th className="b2b-th">Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this_week_resolved?.map((item, index) => {
                          let to =
                            item.type == "incident"
                              ? "/incident/"
                              : "/service-request/";
                          return (
                            <tr
                              className="tr-dashboard"
                              onClick={() => navigate(`${to}${item.ticket_id}`)}
                            >
                              <td>{index + 1}</td>
                              <td>{truncateToEllipsis(item.title)}</td>
                              <td>{capitalize(item.type)}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card mb-4" style={{ border: "none" }}>
                <div className="card-header d-flex flex-row align-items-center justify-content-between svc-card-header">
                  <h5 className="m-0 fw-500 color-black43">Overdue Tickets</h5>
                  {/*<div className="dropdown no-arrow">
                                        <select name='ppn' className='form-control ps-0'>
                                            <option>Select One</option>
                                        </select>
</div>*/}
                </div>
                <div className="card-body">
                  <div>
                    <table
                      className="table table-condensed"
                      style={{
                        borderRadius: 0,
                        marginBottom: 0,
                        padding: "0.4rem",
                        fontSize: "16px",
                      }}
                    >
                      <thead>
                        <tr>
                          <th className="b2b-th">No</th>
                          <th className="b2b-th">Title</th>
                          <th className="b2b-th">Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {overdue_tickets &&
                          overdue_tickets.map((item, index) => {
                            let to =
                              item.type == "incident"
                                ? "/incident/"
                                : "/service-request/";
                            return (
                              <tr
                                className="tr-dashboard"
                                onClick={() =>
                                  navigate(`${to}${item.ticket_id}`)
                                }
                              >
                                <td>{index + 1}</td>
                                <td>{truncateToEllipsis(item.title)}</td>
                                <td>{capitalize(item.type)}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card mb-4" style={{ border: "none" }}>
                <div
                  className="card-header d-flex flex-row align-items-center justify-content-between svc-card-header"
                  style={{ padding: "0.6rem 0.9rem" }}
                >
                  <h5 className="m-0 fw-500 color-black43">Pick Category:</h5>
                  <div className="dropdown no-arrow">
                    <select
                      name="type-selector"
                      id="type-selector"
                      className="sd-form-control ps-0"
                      onChange={(e) => setSelectedType(e.target.value)}
                      ref={selectRef}
                      style={{ fontSize: "0.65rem" }}
                    >
                      <option value="">Select Type</option>
                      <option value="incident">Incident</option>
                      <option value="service-request">Service Request</option>
                    </select>
                  </div>
                </div>
                <div className="card-body">
                  <div>
                    {isObjectEmpty(selected_category) &&
                      table_data.length <= 0 && (
                        <div>
                          <div
                            style={{ textAlign: "center", marginTop: "50px" }}
                          >
                            Please select ticket type first.
                          </div>
                        </div>
                      )}
                    {!isObjectEmpty(selected_category) &&
                      table_data.length <= 0 && (
                        <div>
                          <div
                            style={{ textAlign: "center", marginTop: "50px" }}
                          >
                            No data for this category.
                          </div>
                        </div>
                      )}
                    {table_data.length > 0 && (
                      <table
                        className="table table-condensed"
                        style={{
                          borderRadius: 0,
                          marginBottom: 0,
                          padding: "0.4rem",
                          fontSize: "16px",
                        }}
                      >
                        <thead>
                          <tr>
                            <th className="b2b-th">No</th>
                            <th className="b2b-th">Ticket</th>
                            <th className="b2b-th">Requested Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {table_data?.map((item, index) => {
                            let to =
                              item.type == "incident"
                                ? "/incident/"
                                : "/service-request/";
                            return (
                              <tr
                                className="tr-dashboard"
                                onClick={() => navigate(`${to}${item.id}`)}
                              >
                                <td>{index + 1}</td>
                                <td>
                                  {item.subject
                                    ? `${truncateToEllipsis(item.subject)} - ${
                                        item.category_name
                                      }`
                                    : `${item.category_name}`}
                                </td>
                                <td style={{ fontSize: "13px" }}>
                                  {moment(item.requested_date).format(
                                    "DD-MM-YYYY HH:mm"
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="card mb-4" style={{ border: "none" }}>
                <div className="card-header d-flex flex-row align-items-center justify-content-between svc-card-header">
                  <h5 className="m-0 fw-500 color-black43">
                    Top 5 Incident Categories
                  </h5>
                  {/*<div className="dropdown no-arrow">
                                        <select name='ppn' className='form-control ps-0'>
                                            <option>Select One</option>
                                        </select>
</div>*/}
                </div>
                <div className="card-body">
                  <div className="border-round-light-gray">
                    {show_incident_chart && (
                      <Bar data={incident_data} options={incident_options} />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card mb-4" style={{ border: "none" }}>
                <div className="card-header d-flex flex-row align-items-center justify-content-between svc-card-header">
                  <h5 className="m-0 fw-500 color-black43">
                    Top 5 Service Request Categories
                  </h5>
                  {/*<div className="dropdown no-arrow">
                                        <select name='ppn' className='form-control ps-0'>
                                            <option>Select One</option>
                                        </select>
</div>*/}
                </div>
                <div className="card-body">
                  <div className="border-round-light-gray">
                    {show_request_chart && (
                      <Bar data={request_data} options={request_options} />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card mb-4" style={{ border: "none" }}>
                <div className="card-header d-flex flex-row align-items-center justify-content-between svc-card-header">
                  <h5 className="m-0 fw-500 color-black43">
                    Today's Achievement
                  </h5>
                </div>
                <div className="card-body">
                  <div style={{ height: "257px" }}>
                    {show_achievement_chart && (
                      <Pie
                        data={achievement_data}
                        options={achievement_options}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HomePage;
