import axios from "axios";
import { handleResponse } from "./HelperService";

const getAllManagers = (params, onSuccess, onError) => {
  const response = axios.get("v1/manager", { params });
  return handleResponse(response, onSuccess, onError);
};

const createManager = (payload, onSuccess, onError) => {
  const response = axios.post("v1/manager", payload);
  return handleResponse(response, onSuccess, onError);
};

const deleteManager = (id, company_id, onSuccess, onError) => {
  const response = axios.delete(`v1/manager/${id}/${company_id}`);
  return handleResponse(response, onSuccess, onError);
};

const updateManager = (id, company_id, payload, onSuccess, onError) => {
  const response = axios.put(`v1/manager/${id}/${company_id}`, payload);
  return handleResponse(response, onSuccess, onError);
};

const getManager = (id, company_id, onSuccess, onError) => {
  const response = axios.get(`v1/manager/${id}/${company_id}`);
  return handleResponse(response, onSuccess, onError);
};

export {
  getAllManagers,
  createManager,
  deleteManager,
  updateManager,
  getManager,
};
