import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import MTable from "../../Components/MTable/MTable";
import Overlay from "../../Components/Overlay";
import moment from "moment";
import ActionButton from "../../Components/MTable/ActionButton";
import StatusBadge from "../../Components/MTable/StatusBadge";
import "moment/locale/id";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { capitalize } from "lodash";
import { InputSwitch } from "primereact/inputswitch";
import { getDashboardOverview } from "../../Service/DashboardService";

const { $ } = window;
const DashboardDetail = () => {
  const company_id = localStorage.getItem("company_id");
  let { userInfo } = useSelector((state) => state.auth);
  const incident_fields = [
    "ticket_number",
    "subject",
    "description",
    "urgency",
    "impact",
    "priority",
    "agent_email",
    "agent_name",
    "status",
    "requester",
    "requester_email",
    "requested_for",
    "requested_for_email",
  ];
  const [selected_export_fields, setSelectedExportFields] = useState(null);
  const [filter_from_date, setFilterFromDate] = useState(null);
  const [filter_to_date, setFilterToDate] = useState(null);
  const [filter_date_by, setFilterDateBy] = useState(null);
  const [select_fields_active, setSelectFieldsActive] = useState(false);
  moment.locale("id");
  const {
    formState: { errors },
  } = useForm({
    defaultValues: {
      company_name: "",
      company_address: "",
      mobile_number: "",
      phone_number: "",
      note: "",
      email: "",
      cp_name: "",
    },
  });
  const navigate = useNavigate();
  const tableSO = useRef();
  const [state, setState] = useState({ processing: false });

  const { ticket_type } = useParams();

  const columns = [
    {
      id: 1,
      title: "Ticket Number",
      field: "ticket_number",
      sortable: true,
      style: { width: "13%" },
      render: (data) => {
        let text = data.ticket_number;
        return <b>{text}</b>;
      },
    },
    {
      id: 2,
      title: "Subject",
      field: "subject",
      sortable: true,
      style: { width: "11%" },
      render: (data) => {
        let text = data.subject;
        if (text && text.length > 30) {
          text = text.substring(0, 20) + "...";
        }
        return text;
      },
    },
    {
      id: 3,
      title: "Requested For",
      field: "requested_for",
      sortable: true,
      style:
        userInfo.role_name == "Requester" ? { width: "9%" } : { width: "10%" },
      render: (data) => {
        if (data.requested_for_email) {
          let requester_email = `mailto: ${data.requested_for_email}`;

          return <a href={requester_email}>{data.requested_for}</a>;
        } else return <></>;
      },
    },
    {
      id: 13,
      title: "Category",
      field: "category_name",
      sortable: true,
      style: { width: "12%" },
    },
    {
      id: 4,
      title: "Requested Date",
      field: "requested_date",
      sortable: true,
      style: { width: "10%" },
      render: (data) => {
        return (
          moment(data.requested_date).format("DD MMM YYYY HH:mm") + " (WIB)"
        );
      },
    },

    {
      id: 7,
      title: "Agent",
      field: "agent_name",
      sortable: true,
      style: { width: "10%" },
    },
    {
      id: 99,
      title: "Group",
      field: "group_name",
      sortable: true,
      style: { width: "10%" },
    },
    {
      id: 5,
      title: "Status",
      field: "status_name",
      style: { width: "5%" },
      sortable: false,
      clickable: false,
      setAsSticky: false,
      render: (data) => {
        if (data.status_name == "Pending") {
          return (
            <StatusBadge
              bg_color={"b2b-badge-warning"}
              text={"Pending"}
              custom_width={"100%"}
            />
          );
        } else if (data.status_name == "Open") {
          return (
            <StatusBadge
              bg_color={"b2b-badge-info"}
              text={"Open"}
              custom_width={"80%"}
            />
          );
        } else if (
          data.status_name == "Resolved" ||
          data.status == "Approved"
        ) {
          return (
            <StatusBadge bg_color={"b2b-badge-success"} text={data.status} />
          );
        } else if (data.status_name == "Closed") {
          return (
            <StatusBadge
              bg_color={"b2b-badge-secondary"}
              text={"Closed"}
              custom_width={"90%"}
            />
          );
        } else if (data.status_name == "Cancelled") {
          return (
            <StatusBadge
              bg_color={"b2b-badge-danger-alt"}
              text={"Cancelled"}
              custom_width={"80%"}
            />
          );
        }
      },
    },
    //{ id: 2, title: 'Priority', field: 'priority', sortable: true},
  ];

  const tableGetData = () => {
    let groups = "";
    let filter = {
      filterOr: `agent_id:${userInfo.agent_id},requester_email:${userInfo.email}`,
      filter: `is_deleted:false,ticket_type:incident,company_id:${localStorage.getItem(
        "company_id"
      )}`,
    };
    if (
      userInfo.agent_groups &&
      userInfo.agent_groups.length > 0 &&
      userInfo.agent_groups[0]
    ) {
      filter.filterOr += `,3p_email:${userInfo?.email}`;
      let i = 0;
      userInfo.agent_groups.forEach((item) => {
        groups += item.group_id;
        if (i < userInfo.agent_groups.length - 1) groups += ";";
        i++;
      });
      filter.filterOr += `,group_id:${groups}`;
    }

    return (params) => {
      if (params.filter) {
        filter.filter += "," + params.filter;
      }

      return getDashboardOverview(ticket_type, company_id, {
        ...params,
        ...filter,
      });
    };
  };

  const genTableColumns = (role_name) => {
    columns.push(
      {
        id: 10,
        title: "SLA Status",
        render: (item) => {
          if (item.status == "Open" || item.status == "On Progress")
            return <div style={{ color: "#FF0000" }}>Running</div>;
          else if (
            item.status == "Pending" ||
            item.status == "On Queue" ||
            item.status == "Confirmation"
          )
            return <div style={{ color: "#FFBF00" }}>Paused</div>;
          else return <div style={{ color: "#0BDA51" }}>Completed</div>;
        },
      },
      {
        id: 6,
        title: "Action",
        style: { width: "12%" },
        render: (item) => {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              {/*<ActionButton icon={<MdOutlineModeEdit/>} link_color="#0099C3"/>*/}
              <ActionButton
                icon={<i className="fa fa-eye" />}
                link_color="#0099C3"
                click_action={(e) => {
                  navigate(`/incident/${item.id}`);
                }}
                text="View"
              />
              {/*<ActionButton icon={<BsTrashFill/>} link_color="#FF4833" click_action={(e) => onRemove(e, item.id)}/>*/}
            </div>
          );
        },
      }
    );
    return columns;
  };

  const getTableWidth = (role_name) => {
    return "100%";
  };

  const isStickyEnd = (role_name) => {
    return true;
  };

  const onMapFilter = (filter) => {
    const result = [];
    if (!filter) return result;

    filter.split(",").forEach((item) => {
      const temp = item.split(":");
      let sample_obj = {};
      sample_obj["field"] = temp[0];
      sample_obj["value"] = temp[1];
      sample_obj["title"] = capitalize(temp[0].replace(/_/g, " "));
      result.push(sample_obj);
    });
    return result;
  };

  const propsTable = {
    columns: genTableColumns(userInfo?.role_name),
    getData: tableGetData(),
    showIndex: false,
    addButtonText: "Incident",
    order: "requested_date",
    direction: "desc",
    showCheckbox: true,
    minTableWidth: getTableWidth(userInfo?.role_name),
    stickyEnd: isStickyEnd(userInfo?.role_name),
    tableId: "table-incident",
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6" style={{ paddingLeft: "20px" }}>
              <h2 className="title-breadcrum fw-500">
                {capitalize(ticket_type)} Tickets
              </h2>
              <h6>List of {capitalize(ticket_type)} Tickets</h6>
            </div>
            {userInfo.role_name !== "Requester" && (
              <div className="col-sm-6 right">
                {/* <button type="button" class="btn btn-outline-dark right" style={{padding: "0.5em 1em", margin:"0 5px"}}>
                                <div style={{display: "flex", alignItems:"center"}}>
                                    <div><img src={data_update_dark}/></div>
                                    <div style={{marginLeft: "10px"}}>Import Data</div>
                                </div>
                            </button>*/}
                <button
                  type="button"
                  class="btn btn-export btn-outline-modena right"
                  style={{ padding: "0.5em 1em", margin: "0 5px" }}
                  onClick={() => $("#modal-export").modal("show")}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <i className="fa-solid fa-download" />
                    </div>
                    <div style={{ marginLeft: "10px" }}>Export Data</div>
                  </div>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <section className="content">
        <div class="modal right" id="modal-export" tabindex="-1" role="dialog">
          <div
            class="modal-dialog"
            role="document"
            style={{ maxHeight: "100vh", height: "100vh" }}
          >
            <div class="modal-content" style={{ height: "100vh" }}>
              <div className="modal-header">
                <h5 className="modal-title black">Export Incidents Data</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => $("#modal-export").modal("hide")}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div>
                  <>
                    <div
                      className="ticket-date-filter"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "5px",
                      }}
                    >
                      <div className="bold black">Filter Ticket By:</div>
                      <div className="row" style={{ marginTop: "5px" }}>
                        <div className="col-md-6">
                          <select
                            className="form-select servicedesk-input"
                            aria-label="Default select example"
                            style={{ border: "1px solid black" }}
                            onChange={(e) => setFilterDateBy(e.target.value)}
                          >
                            <option value="">Select Filter</option>
                            <option value="created date">Created Time</option>
                            <option value="resolved date">Resolved Time</option>
                            <option value="closed date">Closed Time</option>
                          </select>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-6">
                              <DatePicker
                                onChange={(time) => setFilterFromDate(time)}
                                name={"filter_from_date"}
                                selected={filter_from_date}
                                autoComplete="off"
                                dateFormat="dd/MM/yyyy"
                                placeholderText="&#128197; From"
                                className="servicedesk-export-datepicker"
                                disabled={!filter_date_by}
                              />
                            </div>
                            <div className="col-md-6">
                              <DatePicker
                                onChange={(time) => setFilterToDate(time)}
                                name={"filter_to_date"}
                                selected={filter_to_date}
                                autoComplete="off"
                                dateFormat="dd/MM/yyyy"
                                placeholderText="&#128197; To"
                                className="servicedesk-export-datepicker"
                                disabled={!filter_date_by}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{ marginTop: "20px", columnGap: "20px" }}
                        className="d-flex align-items-center"
                      >
                        <div>
                          <b className="black">Select Fields To Export</b>
                        </div>
                        <div style={{ paddingTop: "10px" }}>
                          <InputSwitch
                            checked={select_fields_active}
                            onChange={(e) =>
                              setSelectFieldsActive(!select_fields_active)
                            }
                          />
                        </div>
                      </div>
                      {select_fields_active && (
                        <div>
                          <div
                            style={{
                              display: "flex",
                              marginTop: "10px",
                              flexWrap: "wrap",
                              gap: "20px",
                            }}
                          >
                            {incident_fields &&
                              incident_fields.map((item) => {
                                return (
                                  <div
                                    className="form-check flex-export-checkbox"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      columnGap: "10px",
                                    }}
                                  >
                                    <input
                                      className="b2b-checkbox"
                                      type="checkbox"
                                      value={item.toLowerCase()}
                                      onChange={(e) => {
                                        let result = selected_export_fields
                                          ? { ...selected_export_fields }
                                          : {};
                                        if (e.target.checked) {
                                          result[e.target.value] =
                                            e.target.value;
                                        } else {
                                          delete result[e.target.value];
                                        }
                                        setSelectedExportFields(result);
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      style={{ fontWeight: "400" }}
                                    >
                                      {capitalize(item.replace(/_/g, " "))}
                                    </label>
                                  </div>
                                );
                              })}

                            <div>
                              <b>Permanent Fields:</b>
                              <ul style={{ marginTop: "10px" }}>
                                <li>Ticket Type</li>
                                <li>Incident Category</li>
                                <li>Incident Subcategory</li>
                                <li>Incident Item</li>
                                <li>Is Overdue Response</li>
                                <li>Is Overdue Resolve</li>
                                <li>Operational Hours</li>
                                <li>Response Time Duration</li>
                                <li>Resolve Time Duration</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-outline-dark"
                  type="button"
                  style={{ width: 150 }}
                  onClick={() => $("#modal-export").modal("hide")}
                >
                  <i className="fa fa-times"></i> Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="card shadow mb-4">
            <div className="card-body">
              <Overlay display={state.processing} />
              <div>
                <MTable {...propsTable} ref={tableSO} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DashboardDetail;
