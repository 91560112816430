import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MTable from "../../Components/MTable/MTable";
import { getAll } from "../../Service/IncidentService";
import Swal from "sweetalert2";
import Overlay from "../../Components/Overlay";
import moment from "moment";
import ActionButton from "../../Components/MTable/ActionButton";
import StatusBadge from "../../Components/MTable/StatusBadge";
import "moment/locale/id";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ServiceCategories from "../Pages/Settings/CategoryContents";
import { isObjectEmpty } from "../../Utils/Utils";
import { capitalize } from "lodash";
import { getTicketReporting } from "../../Service/DashboardService";
import store from "../../Redux/Store/Store";

const ServiceRequests = () => {
  let { request_search, request_filter } = useSelector((state) => state.app);
  let { userInfo } = useSelector((state) => state.auth);
  moment.locale("id");
  const [filter_from_date, setFilterFromDate] = useState(null);
  const [filter_to_date, setFilterToDate] = useState(null);
  const [filter_date_by, setFilterDateBy] = useState(null);
  const navigate = useNavigate();
  const tableSO = useRef();
  const [showModalExport, setShowModalExport] = useState(false);
  const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);
  const [state] = useState({ processing: false });

  const onAddData = () => {
    navigate("/services");
  };

  const columns = [
    {
      id: 1,
      title: "Ticket Number",
      field: "ticket_number",
      sortable: true,
      style: { width: "10%" },
      render: (data) => {
        return <b>{data.ticket_number}</b>;
      },
    },
    {
      id: 2,
      title: "Service Item",
      field: "category_name",
      sortable: true,
      style: { width: "11%" },
    },
    {
      id: 3,
      title: "Requested For",
      field: "requested_for",
      sortable: true,
      style:
        userInfo?.role_name == "Requester" ? { width: "7%" } : { width: "10%" },
      render: (data) => {
        if (data.requested_for_email) {
          let requester_email = `mailto: ${data.requested_for_email}`;
          return <a href={requester_email}>{data.requested_for}</a>;
        } else return <></>;
      },
    },
    {
      id: 4,
      title: "Requested Date",
      field: "requested_date",
      sortable: true,
      style: { width: "7%" },
      render: (data) => {
        return (
          moment(data.requested_date).format("DD MMM YYYY HH:mm") + " (WIB)"
        );
      },
    },

    {
      id: 7,
      title: "Agent",
      field: "agent_name",
      sortable: true,
      style: { width: "7%" },
    },
    {
      id: 5,
      title: "Status",
      field: "status",
      style: { width: "2%" },
      sortable: false,
      clickable: false,
      setAsSticky: false,
      render: (data) => {
        let badge = "";
        let { status } = data;
        if (
          status == "Pending" ||
          status == "On Queue" ||
          status == "Confirmation"
        ) {
          badge = "b2b-badge-warning";
        } else if (status == "Open" || status == "On Progress") {
          badge = "b2b-badge-info";
        } else if (status == "Resolved" || status == "Approved") {
          badge = "b2b-badge-success";
        } else if (status == "Rejected" || status == "Cancelled") {
          badge = "b2b-badge-danger-alt";
        } else if (status == "Closed") {
          badge = "b2b-badge-secondary";
        }
        return <StatusBadge bg_color={badge} text={status} />;
      },
    },
  ];

  const showAddButton = (role_name) => {
    if (!role_name) return false;
    else return true;
  };

  const tableGetData = (role_name) => {
    const email = userInfo?.email.trimStart().trimEnd();
    if (role_name == "Manager") {
      let groups = "";
      let filter = {
        filterOr: `requester_email:${email}`,
        filter: `is_deleted:false,ticket_type:service request,company_id:${localStorage.getItem(
          "company_id"
        )}`,
      };
      if (
        userInfo?.manager_groups &&
        userInfo?.manager_groups.length > 0 &&
        userInfo?.manager_groups[0]
      ) {
        filter.filterOr += `,3p_email:${userInfo?.email}`;
        let i = 0;
        userInfo?.manager_groups.forEach((item) => {
          groups += item.value;
          if (i < userInfo?.manager_groups.length - 1) groups += ";";
          i++;
        });
        filter.filterOr += `,group_id:${groups}`;
      }
      return (params) => {
        if (request_filter) {
          filter.filter += "," + request_filter;
        } else {
          if (params.filter) {
            filter.filter += "," + params.filter;
          }
        }

        store.dispatch({
          type: "REQUEST_FILTER",
          payload: params.filter,
        });
        if (request_search && !params.search) params.search = request_search;
        store.dispatch({
          type: "REQUEST_SEARCH",
          payload: params.search,
        });
        return getAll({ ...params, ...filter });
      };
    }
    if (role_name == "Agent Supervisor" || role_name == "Agent") {
      let groups = "";
      let filter = {
        filterOr: `agent_id:${userInfo?.agent_id},requester_email:${email}`,
        filter: `is_deleted:false,ticket_type:service request,company_id:${localStorage.getItem(
          "company_id"
        )}`,
      };
      if (
        userInfo?.agent_groups &&
        userInfo?.agent_groups.length > 0 &&
        userInfo?.agent_groups[0]
      ) {
        filter.filterOr += `,3p_email:${userInfo?.email}`;
        let i = 0;
        userInfo?.agent_groups.forEach((item) => {
          groups += item.group_id;
          if (i < userInfo?.agent_groups.length - 1) groups += ";";
          i++;
        });
        filter.filterOr += `,group_id:${groups}`;
      }
      return (params) => {
        if (request_filter) {
          filter.filter += "," + request_filter;
        } else {
          if (params.filter) {
            filter.filter += "," + params.filter;
          }
        }

        store.dispatch({
          type: "REQUEST_FILTER",
          payload: params.filter,
        });
        if (request_search && !params.search) params.search = request_search;
        store.dispatch({
          type: "REQUEST_SEARCH",
          payload: params.search,
        });
        return getAll({ ...params, ...filter });
      };
    } else if (role_name == "Requester") {
      let filter = {
        filterOr: `requester_email:${email},3p_email:${email}`,
        filter: `is_deleted:false,ticket_type:service request,company_id:${localStorage.getItem(
          "company_id"
        )}`,
      };
      return (params) => {
        if (request_filter) {
          filter.filter += "," + request_filter;
        } else {
          if (params.filter) {
            filter.filter += "," + params.filter;
          }
        }

        store.dispatch({
          type: "REQUEST_FILTER",
          payload: params.filter,
        });
        if (request_search) params.search = request_search;
        //if (params.search) search = params.search;

        store.dispatch({
          type: "REQUEST_SEARCH",
          payload: params.search,
        });

        return getAll({ ...params, ...filter });
      };
    } else {
      //let search = "";
      //if (request_search) search = request_search;

      return (params) => {
        if (request_search && !params.search) params.search = request_search;
        store.dispatch({
          type: "REQUEST_SEARCH",
          payload: params.search,
        });

        if (request_filter && !params.filter) params.filter = request_filter;
        store.dispatch({
          type: "REQUEST_FILTER",
          payload: params.filter,
        });

        return getAll({
          ...params,
          filter: `company_id:${localStorage.getItem(
            "company_id"
          )},is_deleted:false,ticket_type:service request,${
            params.filter || ""
          }`,
        });
      };
    }
  };

  const genTableColumns = (role_name) => {
    if (role_name !== "Requester") {
      columns.push(
        {
          id: 10,
          title: "SLA Status",
          field: "sla_state",
          render: (item) => {
            if (
              item.status !== "Cancelled" &&
              item.status !== "Closed" &&
              item.status !== "Approved" &&
              item.status !== "Rejected"
            ) {
              if (item.sla_state == "RUNNING")
                return <div style={{ color: "#FF0000" }}>Running</div>;
              else if (item.status == "Pending")
                return <div style={{ color: "#FFBF00" }}>Stopped</div>;
              else return <div style={{ color: "#FFBF00" }}>Stopped</div>;
            } else return <div style={{ color: "#FFBF00" }}>Stopped</div>;
          },
        },
        {
          id: 3,
          title: "Requester",
          field: "requester",
          sortable: true,
          style: { width: "10%" },
          render: (data) => {
            let requester_email = `mailto: ${data.requester_email}`;
            return <a href={requester_email}>{data.requester}</a>;
          },
        },
        {
          id: 19,
          title: "Group",
          field: "group_name",
          sortable: true,
          style: { width: "10%" },
        },
        {
          id: 9,
          title: "Urgency",
          field: "urgency",
          style: { width: "8%" },
          sortable: true,
          clickable: false,
          render: (data) => {
            let color = "";
            let icon = "";
            if (data.urgency == "low") {
              color = "#39E3A7";
            } else if (data.urgency == "medium") {
              color = "#FFC300";
            } else if (data.urgency == "high") {
              color = "#FF7F50";
            } else if (data.urgency == "urgent") {
              color = "#FF2400";
            } else {
              color = "black";
            }
            return (
              <div
                style={{
                  display: "flex",
                  color,
                  fontWeight: "700",
                  columnGap: "5px",
                }}
              >
                <div>
                  <div style={{ fontSize: "12px", paddingTop: "2px" }}>
                    <i className="fas fa-circle"></i>
                  </div>
                </div>
                <div>{capitalize(data.urgency)}</div>
              </div>
            );
          },
        },
        {
          id: 6,
          title: "Action",
          style: { width: "5%" },
          setAsSticky: true,
          render: (item) => {
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                {/*<ActionButton icon={<BsTrashFill/>} link_color="#FF4833" click_action={onRemove}/>*/}
                <ActionButton
                  href={`/service-request/${item.id}`}
                  icon={
                    <i
                      className="fas fa-eye"
                      style={{ fontSize: "0.7rem" }}
                    ></i>
                  }
                  link_color="#0099C3"
                  text="View"
                  spa_mode={true}
                />
              </div>
            );
          },
        }
      );
      return columns;
    } else {
      columns.push({
        id: 6,
        title: "Action",
        style: { width: "5%" },
        render: (item) => {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              {/*<ActionButton icon={<BsTrashFill/>} link_color="#FF4833" click_action={onRemove}/>*/}
              <ActionButton
                icon={
                  <i className="fas fa-eye" style={{ fontSize: "0.7rem" }}></i>
                }
                link_color="#0099C3"
                href={`/service-request/${item.id}`}
                text="View"
                spa_mode={true}
              />
            </div>
          );
        },
      });
      return columns;
    }
  };

  const getTableWidth = (role_name) => {
    //if(role_name !== "Requester") return "110%";
    //else
    return "100%";
  };

  const isStickyEnd = (role_name) => {
    //if(role_name !== "Requester") return true;
    //else
    return false;
  };

  const onMapFilter = (filter) => {
    const result = [];
    if (!filter) return result;

    filter.split(",").forEach((item) => {
      const temp = item.split(":");
      let sample_obj = {};
      sample_obj["field"] = temp[0];
      sample_obj["value"] = temp[1];
      sample_obj["title"] = capitalize(temp[0].replace(/_/g, " "));
      result.push(sample_obj);
    });
    return result;
  };

  const propsTable = {
    filterInit: onMapFilter(request_filter),
    onFilterClear: () => {
      store.dispatch({ type: "REQUEST_FILTER", payload: "" });
    },
    searchInit: request_search,
    columns: genTableColumns(userInfo?.role_name),
    getData: tableGetData(userInfo?.role_name),
    showIndex: false,
    showAddButton: showAddButton(userInfo?.role_name),
    addButtonText: "Service Request",
    onSearchClear: () => {
      store.dispatch({ type: "REQUEST_SEARCH", payload: "" });
    },
    onAddData,
    order: "requested_date",
    direction: "desc",
    showCheckbox: true,
    minTableWidth: getTableWidth(userInfo?.role_name),
    stickyEnd: isStickyEnd(userInfo?.role_name),
  };

  const [selected_category, setSelectedCategory] = useState({});

  const onCloseServiceCategory = (e) => {
    e.preventDefault();
    setSelectedCategory({});
    setIsServiceModalOpen(false);
  };

  const onShowServiceCategoryModal = () => {
    setIsServiceModalOpen(true);
  };

  const onSetCategory = (category_obj) => {
    setSelectedCategory(category_obj);
    setIsServiceModalOpen(false);
  };

  const getReport = (category) => {
    let export_all_items = false;
    if (!category || !category.id) {
      export_all_items = true;
    }

    if (filter_date_by && !filter_from_date) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select from date!",
      });

      return;
    }

    if (filter_date_by && !filter_to_date) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select to date!",
      });

      return;
    }

    const payload = {
      category_id: category.id,
      filter_from_date: moment(filter_from_date).format("YYYY-MM-DD"),
      filter_to_date: moment(filter_to_date).format("YYYY-MM-DD"),
      filter_date_by,
      type: "service request",
      export_all_items,
      company_id: localStorage.getItem("company_id"),
    };

    if (userInfo?.company_id) payload["company_id"] = userInfo?.company_id;
    getTicketReporting(payload).then((res) => {
      if (res.status == 200) {
        if (!res.data.file) {
          Swal.fire({
            icon: "info",
            title: "No report found",
            text: "Please pick another service item and filter",
          });

          return;
        }
        const link = document.createElement("a");
        link.href = `${process.env.REACT_APP_IMAGE_URL}${process.env.REACT_APP_SFTP_REPORT_FOLDER}${res.data.file}`;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  return (
    <div className="content-wrapper">
      {isServiceModalOpen && (
        <div
          id="modal-service-category"
          className="modal fade"
          style={{ zIndex: "9999" }}
        >
          <div className="modal-dialog modal-lg" style={{ maxWidth: 1000 }}>
            <div className="modal-content" style={{ width: "120%" }}>
              <div className="modal-header">
                <h5 className="modal-title">Select Service Category</h5>
                <button
                  type="button"
                  className="close"
                  onClick={onCloseServiceCategory}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <ServiceCategories
                  sendCategoryToParent={onSetCategory}
                  all_categories_included={true}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6" style={{ paddingLeft: "20px" }}>
              <h2 className="title-breadcrum fw-500">Service Requests</h2>
              <h6>List of Services Requested</h6>
            </div>
            {userInfo?.role_name !== "Requester" && (
              <div className="col-sm-6 right">
                {/* <button type="button" class="btn btn-outline-dark right" style={{padding: "0.5em 1em", margin:"0 5px"}}>
                                <div style={{display: "flex", alignItems:"center"}}>
                                    <div><img src={data_update_dark}/></div>
                                    <div style={{marginLeft: "10px"}}>Import Data</div>
                                </div>
                            </button>*/}
                <button
                  type="button"
                  class="btn btn-export btn-outline-modena right"
                  style={{ padding: "0.5em 1em", margin: "0 5px" }}
                  onClick={() => setShowModalExport(true)}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <i
                        className="fa-solid fa-download"
                        style={{ fontSize: "0.7rem" }}
                      ></i>
                    </div>
                    <div style={{ marginLeft: "10px" }}>Export Data</div>
                  </div>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <section className="content">
        {showModalExport && (
          <div
            className="modal show"
            style={{ display: "block" }}
            id="modal-export"
            tabindex="-1"
            role="dialog"
          >
            <div
              class="modal-dialog"
              role="document"
              style={{ maxWidth: "700px" }}
            >
              <div class="modal-content" style={{ width: "40vw" }}>
                <div className="modal-header">
                  <h5 className="modal-title black">
                    Export Service Requests Data
                  </h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowModalExport(false)}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div>
                    <div>
                      <div>
                        <div className="bold black">
                          You can export all service items:
                        </div>
                      </div>
                      {isObjectEmpty(selected_category) && (
                        <div
                          className="ticket-date-filter"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: "5px",
                            marginTop: "15px",
                          }}
                        >
                          <div className="row" style={{ marginTop: "5px" }}>
                            <div className="col-md-6">
                              <select
                                className="form-select servicedesk-input"
                                aria-label="Default select example"
                                style={{ border: "1px solid black" }}
                                onChange={(e) =>
                                  setFilterDateBy(e.target.value)
                                }
                              >
                                <option value="">Select Filter</option>
                                <option value="created date">
                                  Created Time
                                </option>
                                <option value="resolved date">
                                  Resolved Time
                                </option>
                                <option value="closed date">Closed Time</option>
                                <option value="approval date">
                                  Approval Time
                                </option>
                              </select>
                            </div>
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-6">
                                  <DatePicker
                                    onChange={(time) => setFilterFromDate(time)}
                                    name={"filter_from_date"}
                                    selected={filter_from_date}
                                    autoComplete="off"
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="&#128197; From Date"
                                    className="servicedesk-export-datepicker"
                                    disabled={!filter_date_by}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <DatePicker
                                    onChange={(time) => setFilterToDate(time)}
                                    name={"filter_to_date"}
                                    selected={filter_to_date}
                                    autoComplete="off"
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="&#128197; To Date"
                                    className="servicedesk-export-datepicker"
                                    disabled={!filter_date_by}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className="ticket-category-filter"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        columnGap: "10px",
                        marginTop: "40px",
                      }}
                    >
                      <div className="bold black">
                        ...or pick a specific service item to export:
                      </div>
                      <div>
                        {isObjectEmpty(selected_category) && (
                          <button
                            type="button"
                            className="btn"
                            onClick={onShowServiceCategoryModal}
                            style={{
                              cursor: "pointer",
                              border: "1px solid black",
                              background: "black",
                              color: "white",
                              borderRadius: "8px",
                              fontSize: "16px",
                            }}
                          >
                            <i className="fas fa-check-square" /> Pick an item
                          </button>
                        )}
                        {!isObjectEmpty(selected_category) && (
                          <div
                            style={{
                              display: "flex",
                              alignItem: "center",
                              columnGap: "10px",
                            }}
                          >
                            <div
                              style={{ color: "red", cursor: "pointer" }}
                              onClick={(e) => setSelectedCategory({})}
                            >
                              <i className="fas fa-times-circle"></i>
                            </div>
                            <div
                              style={{
                                border: "solid 1px #ccc",
                                borderRadius: 4,
                              }}
                            >
                              <span>{selected_category.category_name}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {!isObjectEmpty(selected_category) && (
                      <div
                        className="ticket-date-filter"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "5px",
                          marginTop: "30px",
                        }}
                      >
                        <div className="bold black">Filter Ticket By:</div>
                        <div className="row" style={{ marginTop: "5px" }}>
                          <div className="col-md-6">
                            <select
                              className="form-select servicedesk-input"
                              aria-label="Default select example"
                              style={{ border: "1px solid black" }}
                              onChange={(e) => setFilterDateBy(e.target.value)}
                            >
                              <option value="">Select Filter</option>
                              <option value="created date">Created Time</option>
                              <option value="resolved date">
                                Resolved Time
                              </option>
                              <option value="closed date">Closed Time</option>
                            </select>
                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-6">
                                <DatePicker
                                  onChange={(time) => setFilterFromDate(time)}
                                  name={"filter_from_date"}
                                  selected={filter_from_date}
                                  autoComplete="off"
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="&#128197; From Date"
                                  className="servicedesk-export-datepicker"
                                  disabled={!filter_date_by}
                                />
                              </div>
                              <div className="col-md-6">
                                <DatePicker
                                  onChange={(time) => setFilterToDate(time)}
                                  name={"filter_to_date"}
                                  selected={filter_to_date}
                                  autoComplete="off"
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="&#128197; To Date"
                                  className="servicedesk-export-datepicker"
                                  disabled={!filter_date_by}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-outline-dark"
                    type="button"
                    style={{ width: 150 }}
                    onClick={() => setShowModalExport(false)}
                  >
                    <i className="fa fa-times"></i> Close
                  </button>

                  <button
                    className="btn"
                    type="button"
                    style={{
                      width: 150,
                      background: "#3576C2",
                      color: "white",
                    }}
                    onClick={() => getReport(selected_category)}
                  >
                    <i className="fa fa-check"></i> Export
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="container-fluid">
          <div className="card shadow mb-4">
            <div className="card-body">
              <Overlay display={state.processing} />
              <div>
                <MTable {...propsTable} ref={tableSO} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServiceRequests;
