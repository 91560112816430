import axios from "axios";
import { handleResponse } from "./HelperService";
import { isObjectEmpty } from "../Utils/Utils";

const getDashboardData = (company_id, params, onSuccess, onError) => {
  const response = axios.get(`v1/dashboard/${company_id}`, { params });
  return handleResponse(response, onSuccess, onError);
};

const getDashboardOverview = (type, company_id, params, onSuccess, onError) => {
  const response = axios.get(`v1/dashboard-overview/${type}/${company_id}`, {
    params,
  });
  return handleResponse(response, onSuccess, onError);
};

const getTicketReporting = (params, onSuccess, onError) => {
  const response = axios.post("v1/ticket-reporting", { params });
  return handleResponse(response, onSuccess, onError);
};

const getProductivityReport = (params, onSuccess, onError) => {
  const response = axios.get(
    `v1/productivity-report/${params.month}/${params.year}/${params.mode}/${params.company_id}`
  );
  return handleResponse(response, onSuccess, onError);
};

export {
  getDashboardData,
  getTicketReporting,
  getDashboardOverview,
  getProductivityReport,
};
