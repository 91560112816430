import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MTable from "../../../Components/MTable/MTable";
import { deleteHour, getAllHours } from "../../../Service/BusinessHoursService";
import Swal from "sweetalert2";
import { InputSwitch } from "primereact/inputswitch";
import Overlay from "../../../Components/Overlay";
import moment from "moment";
import ActionButton from "../../../Components/MTable/ActionButton";
import "moment/locale/id";
import { permissionCheck } from "../../../Utils/Utils";

const BusinessHoursSettings = () => {
  let { userInfo } = useSelector((state) => state.auth);
  moment.locale("id");
  const navigate = useNavigate();
  const tableAgent = useRef();
  const [state] = useState({ processing: false });

  const onAddData = () => {
    //$('#modal-document').modal();
    navigate("/business-hours-form");
  };

  useEffect(() => {
    if (userInfo?.access) {
      if (userInfo?.access.settings) {
        if (!userInfo?.access.settings.can_view) {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "You're not allowed to access that page!",
          });
          if (userInfo?.role_name == "Requester") {
            navigate("/requester-landing");
          } else {
            navigate("/");
          }
        }
      }
    }
  }, []);

  const onRemove = (hour_id) => {
    const swalWithBootstrapButtons = Swal.mixin({});

    swalWithBootstrapButtons
      .fire({
        icon: "info",
        title: "Delete Business Hours",
        text: "Are you sure you want to delete this business hours?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteHour(hour_id).then((res) => {
            //console.log(res);
            if (res.data.status == 200) {
              swalWithBootstrapButtons
                .fire("Deleted!", "Business hour has been deleted.", "success")
                .then((_) => tableAgent.current.refresh());
            } else {
              let msg = "Deletion failed. Please try again or contact support!";
              if (res.data.message) msg = res.data.message;
              swalWithBootstrapButtons.fire("Error", msg, "error");
            }
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Business hour deletion cancelled",
            "error"
          );
        }
      });
  };

  const columns = [
    {
      id: 1,
      title: "Business Hour Name",
      field: "business_hour",
      sortable: true,
      style: { width: 300 },
    },
    {
      id: 2,
      title: "Business Hour Description",
      field: "business_hour_description",
      sortable: false,
      style: { width: 700 },
      render: (data) => {
        let text = data.business_hour_description;
        if (text.length > 100) {
          text = text.substring(0, 90) + "...";
        }
        return text;
      },
    },
    {
      id: 3,
      title: "Active Status",
      field: "business_hour_enabled",
      sortable: true,
      filter_text:
        "Please type in lower case: 'true' for active, 'false' for inactive",
      render: (item) => {
        return (
          <InputSwitch checked={item.business_hour_enabled == true} disabled />
        );
      },
    },
  ];

  if (
    permissionCheck(userInfo, "settings", "delete") &&
    permissionCheck(userInfo, "settings", "update")
  ) {
    columns.push({
      id: 2,
      title: "Action",
      style: { width: 200 },
      render: (item) => {
        return (
          <div>
            <ActionButton
              icon={<i className="fas fa-edit"></i>}
              link_color="#0099C3"
              click_action={(e) => navigate(`/business-hours-form/${item.id}`)}
            />
            <ActionButton
              icon={<i className="fas fa-trash"></i>}
              link_color="#FF4833"
              click_action={(e) => onRemove(item.id)}
            />
            <ActionButton
              icon={
                <i className="fa fa-eye" style={{ fontSize: "0.7rem" }}></i>
              }
              link_color="#0099C3"
              click_action={() => navigate(`/business-holidays/${item.id}`)}
              text="Holidays"
            />
          </div>
        );
      },
    });
  }

  const showAddButton = (access) => {
    if (access) {
      if (access.settings) {
        if (access.settings.can_view) return true;
      }
    }
    return false;
  };

  const tableGetData = () => {
    return getAllHours;
  };

  const genTableColumns = () => {
    return columns;
  };

  const getTableWidth = () => {
    return "100%";
  };

  const isStickyEnd = () => {
    return false;
  };

  const propsTable = {
    columns: genTableColumns(),
    getData: tableGetData(),
    showIndex: false,
    showAddButton: showAddButton(userInfo?.access),
    addButtonText: "Business Hour",
    onAddData,
    order: "business_hour",
    direction: "asc",
    showCheckbox: false,
    minTableWidth: getTableWidth(),
    stickyEnd: isStickyEnd(),
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h2 className="title-breadcrum fw-500">Business Hours</h2>
              <h6>List of Business Hours</h6>
            </div>
            {/*<div className="col-sm-6 right">
                            <button type="button" class="btn btn-outline-dark right" style={{padding: "0.5em 1em", margin:"0 5px"}}>
                                <div style={{display: "flex", alignItems:"center"}}>
                                    <div><img src={data_update_dark}/></div>
                                    <div style={{marginLeft: "10px"}}>Import Data</div>
                                </div>
                            </button>
                           
                        </div>*/}
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="card shadow mb-4">
            <div className="card-body">
              <Overlay display={state.processing} />
              <div>
                <MTable {...propsTable} ref={tableAgent} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BusinessHoursSettings;
