import React from "react";
import ScrollToTop from "react-scroll-to-top";

const ScrollTop = () => {
  return (
    <ScrollToTop
      smooth
      color="orange"
      style={{ background: "black" }}
      component={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "10px",
            justifyContent: "center",
          }}
        >
          <div style={{ color: "white" }}>
            <i className="fa-solid fa-arrow-up" />
          </div>
          <div>
            <span style={{ color: "white" }}>Back To Top</span>
          </div>
        </div>
      }
    />
  );
};

export default ScrollTop;
