import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MTable from "../../../Components/MTable/MTable";
import { deleteAgent, getAllAgents } from "../../../Service/AgentService";
import {
  deleteOverseer,
  getAllOverseers,
} from "../../../Service/OverseerService";
import {
  deleteRequester,
  getAllRequesters,
} from "../../../Service/RequesterService";
import {
  deleteAdministrator,
  getAllAdministrators,
} from "../../../Service/AdministratorService";
import { deleteManager, getAllManagers } from "../../../Service/ManagerService";
import Swal from "sweetalert2";
import { InputSwitch } from "primereact/inputswitch";
import Overlay from "../../../Components/Overlay";
import moment from "moment";
import ActionButton from "../../../Components/MTable/ActionButton";
import "moment/locale/id";
import { permissionCheck } from "../../../Utils/Utils";
import { TabView, TabPanel } from "primereact/tabview";

const UserSettings = () => {
  let { userInfo } = useSelector((state) => state.auth);
  moment.locale("id");
  const navigate = useNavigate();
  const tableRef = useRef();
  const [state] = useState({ processing: false });
  const company_id = localStorage.getItem("company_id");
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.refresh();
    }
  }, [activeTab]);

  const fetchTableData = (role) => {
    if (role.toLowerCase() === "agents") {
      return (params) => {
        if (params.filter) {
          params.filter += `,is_deleted:false,company_id:${localStorage.getItem(
            "company_id"
          )}`;
        } else {
          params.filter = `is_deleted:false,company_id:${localStorage.getItem(
            "company_id"
          )}`;
        }
        return getAllAgents(params);
      };
    }
    if (role.toLowerCase() === "reviewers") {
      return (params) => {
        params.filter = params.filter
          ? `${params.filter},company_id:${company_id}`
          : `company_id:${company_id}`;
        return getAllOverseers(params);
      };
    }
    if (role.toLowerCase() === "requesters") {
      return (params) => {
        params.filter = params.filter
          ? `${params.filter},company_id:${company_id}`
          : `company_id:${company_id}`;
        return getAllRequesters(params);
      };
    }
    if (role.toLowerCase() === "administrators") {
      return (params) => {
        params.filter = params.filter
          ? `${params.filter},company_id:${company_id}`
          : `company_id:${company_id}`;
        return getAllAdministrators(params);
      };
    }
    if (role.toLowerCase() === "managers") {
      return (params) => {
        params.filter = params.filter
          ? `${params.filter},company_id:${company_id}`
          : `company_id:${company_id}`;
        return getAllManagers(params);
      };
    }
    return () => Promise.resolve([]);
  };

  const getOrderField = (role) => {
    if (role.toLowerCase() === "agents") return "agent_name";
    else return "full_name";
  };

  const getColumns = (role) => {
    if (role.toLowerCase() === "agents") return agent_columns;
    if (role.toLowerCase() === "reviewers") return reviewer_columns;
    if (role.toLowerCase() === "requesters") return requester_columns;
    if (role.toLowerCase() === "administrators") return administrator_columns;
    if (role.toLowerCase() === "managers") return manager_columns;
    return columns;
  };

  const columns = [
    { id: 1, title: "Name", field: "full_name", sortable: true },
    { id: 2, title: "Email", field: "email", sortable: true },
  ];

  const agent_columns = [
    { id: 1, title: "Agent Name", field: "agent_name", sortable: true },
    { id: 2, title: "Agent ID", field: "agent_user_id", sortable: true },
    {
      id: 3,
      title: "Status",
      field: "agent_enabled",
      sortable: true,
      render: (item) => <InputSwitch checked={item.agent_enabled} disabled />,
    },
  ];

  const reviewer_columns = [
    { id: 1, title: "Reviewer Name", field: "full_name", sortable: true },
    { id: 2, title: "Reviewer Email", field: "email", sortable: true },
  ];

  const requester_columns = [...columns];
  const administrator_columns = [...columns];
  const manager_columns = [...columns];

  const role_names = [
    "agent",
    "requester",
    "reviewer",
    "administrator",
    "manager",
  ];

  const editAgent = (agent_id) => {
    navigate(`/user-form/${role_names[activeTab]}/${agent_id}`);
  };

  if (
    permissionCheck(userInfo, "settings", "delete") &&
    permissionCheck(userInfo, "settings", "update")
  ) {
    agent_columns.push({
      id: 4,
      title: "Action",
      render: (item) => (
        <div>
          <ActionButton
            icon={<i className="fas fa-edit"></i>}
            link_color="#0099C3"
            click_action={() => editAgent(item.id)}
          />
          <ActionButton
            icon={<i className="fas fa-trash"></i>}
            link_color="#FF4833"
            click_action={() => onRemove(item.agent_user_id)}
          />
        </div>
      ),
    });
    reviewer_columns.push({
      id: 4,
      title: "Action",
      render: (item) => (
        <div>
          <ActionButton
            icon={<i className="fas fa-trash"></i>}
            link_color="#FF4833"
            click_action={() => onRemove(item.email)}
          />
        </div>
      ),
    });
    manager_columns.push({
      id: 4,
      title: "Action",
      render: (item) => (
        <div>
          <ActionButton
            icon={<i className="fas fa-edit"></i>}
            link_color="#0099C3"
            click_action={() => editAgent(item.email)}
          />
          <ActionButton
            icon={<i className="fas fa-trash"></i>}
            link_color="#FF4833"
            click_action={() => onRemove(item.email)}
          />
        </div>
      ),
    });
    requester_columns.push({
      id: 4,
      title: "Action",
      render: (item) => (
        <div>
          <ActionButton
            icon={<i className="fas fa-trash"></i>}
            link_color="#FF4833"
            click_action={() => onRemove(item.email)}
          />
        </div>
      ),
    });
    administrator_columns.push({
      id: 4,
      title: "Action",
      render: (item) => (
        <div>
          <ActionButton
            icon={<i className="fas fa-trash"></i>}
            link_color="#FF4833"
            click_action={() => onRemove(item.email)}
          />
        </div>
      ),
    });
  }

  const onRemove = (agent_id) => {
    Swal.fire({
      icon: "info",
      title: `Delete User`,
      text: "Are you sure you want to delete this user?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        // Check if the active tab is "Reviewers"
        if (roles[activeTab] === "Reviewers") {
          deleteOverseer(agent_id, company_id).then((res) => {
            if (res.data.status === 200) {
              Swal.fire(
                "Deleted!",
                "Reviewer has been deleted.",
                "success"
              ).then(() => tableRef.current.refresh());
            } else {
              Swal.fire("Error", "Requester deletion failed.", "error");
            }
          });
        } else if (roles[activeTab] === "Managers") {
          deleteManager(agent_id, company_id).then((res) => {
            if (res.data.status === 200) {
              Swal.fire(
                "Deleted!",
                "Manager has been deleted.",
                "success"
              ).then(() => tableRef.current.refresh());
            } else {
              Swal.fire("Error", "Manager deletion failed.", "error");
            }
          });
        } else if (roles[activeTab] === "Requesters") {
          deleteRequester(agent_id, company_id).then((res) => {
            if (res.data.status === 200) {
              Swal.fire(
                "Deleted!",
                "Requester has been deleted.",
                "success"
              ).then(() => tableRef.current.refresh());
            } else {
              Swal.fire("Error", "Requester deletion failed.", "error");
            }
          });
        } else if (roles[activeTab] === "Administrators") {
          deleteAdministrator(agent_id, company_id).then((res) => {
            if (res.data.status === 200) {
              Swal.fire(
                "Deleted!",
                "Administrator has been deleted.",
                "success"
              ).then(() => tableRef.current.refresh());
            } else {
              Swal.fire("Error", "Administrator deletion failed.", "error");
            }
          });
        } else {
          deleteAgent(agent_id, company_id).then((res) => {
            if (res.status === 200) {
              Swal.fire("Deleted!", "Agent has been deleted.", "success").then(
                () => tableRef.current.refresh()
              );
            } else {
              Swal.fire("Error", "Agent deletion failed.", "error");
            }
          });
        }
      }
    });
  };

  const roles = [
    "Agents",
    "Requesters",
    "Reviewers",
    "Administrators",
    "Managers",
  ];

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-12 flex">
              <div>
                {" "}
                <h2 className="title-breadcrum fw-500">User Settings</h2>
                <h6>Manage different user roles</h6>
                <p className="text-danger mt-2">
                  * A user can only have <b>one role in one department.</b>
                </p>
              </div>

              <div style={{ marginLeft: "auto" }}>
                <button
                  className="btn btn-primary ml-2"
                  style={{ backgroundColor: "#0099C3" }}
                  onClick={() => navigate(`/user-form`)}
                >
                  + Create User
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="content">
        <div className="container-fluid">
          <div className="card shadow mb-4">
            <div className="card-body">
              <Overlay display={state.processing} />
              <TabView
                activeIndex={activeTab}
                onTabChange={(e) => setActiveTab(e.index)}
              >
                {roles.map((role, index) => (
                  <TabPanel key={index} header={role}>
                    {activeTab === index && (
                      <MTable
                        columns={getColumns(role)}
                        getData={fetchTableData(role)}
                        showIndex={false}
                        order={getOrderField(role)}
                        direction="asc"
                        showCheckbox={true}
                        ref={tableRef}
                      />
                    )}
                  </TabPanel>
                ))}
              </TabView>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UserSettings;
