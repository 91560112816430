import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAllCompanies } from "../../Service/CompanyService";
import Swal from "sweetalert2";
import { wrapText } from "../../Utils/Utils";
import { useLocation } from "react-router-dom";
import { modena } from "../../Images";
import { useDispatch } from "react-redux";
import { login } from "../../Redux/Action/AuthAction";
import { useSelector } from "react-redux";

const DepartmentSelect = () => {
  const location = useLocation();
  let { data } = location.state || {};
  let { userInfo } = useSelector((state) => state.auth);
  if (!data && userInfo) {
    data = userInfo;
  }
  const [departments, setDepartments] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  //const { name, session_id } = useParams();

  const loginClick = (company) => {
    /*if (localStorage.getItem("access_token")) {
      localStorage.setItem("company_id", company);
      if (data.role_name === "Requester") {
        navigate("/requester-home");
        return;
      } else {
        navigate("/dashboard");
        return;
      }
    }*/
    const user_data = {
      username: data.email,
      //password,
      company,
      validation_token: localStorage.getItem("validation_token"),
    };
    dispatch(
      login(navigate, user_data, ({ response }) => {
        if (response?.data && response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: response?.data?.message,
            timer: 1500,
          });
        }
      })
    );
  };

  useEffect(() => {
    const validation_token = localStorage.getItem("validation_token");
    const access_token = localStorage.getItem("access_token");
    if (!validation_token && !access_token) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "User validation failed!",
        timer: 1500,
      }).then(() => {
        navigate("/login");
      });
    }
    getAllCompanies()
      .then((response) => {
        if (response && response?.data && response?.data?.data) {
          const companies = response?.data.data;
          if (companies.length > 0) {
            const companies_list = {};
            companies.forEach((company) => {
              companies_list[company.id] = company.company_name;
            });
            localStorage.setItem("companies", JSON.stringify(companies_list));
            setDepartments(companies);
          }
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Fail to get company data!",
          timer: 1500,
        });
      });
  }, []);

  return (
    <div id="department-select-bg">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flex: "3 1 0% !important",
        }}
      >
        <div
          className="fade-in"
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "70%",
          }}
        >
          <div
            style={{ marginLeft: "20px", marginTop: "1.5rem", color: "black" }}
          >
            <img id="department-select-logo" src={modena} />
          </div>
          <div
            style={{ marginRight: "20px", marginTop: "1.5rem", color: "black" }}
          >
            Hi, <b>{data?.employe_name || data?.full_name || data?.fullname}</b>
          </div>
        </div>
      </div>

      <div id="department-select-div">
        <div
          className="d-flex"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <div style={{ fontSize: "1.5rem", fontWeight: "500" }}>
            <div className="fade-in" style={{ textAlign: "center" }}>
              Select a Service
            </div>
            <div
              className="flex"
              style={{
                justifyContent: "space-between",
                margin: "2rem 2rem",
                flexWrap: "wrap",
              }}
            >
              {departments?.map((department, index) => {
                return (
                  <>
                    <div
                      className="card department-select-card slide-in-right"
                      onClick={() => loginClick(department?.id)}
                    >
                      <span style={{ color: "#A9A9A9", fontSize: "1rem" }}>
                        0{index + 1}
                      </span>
                      <h1
                        style={{
                          textAlign: "left",
                          marginTop: "2rem",
                          fontSize: "clamp(1rem, 2rem, 3rem)",
                        }}
                      >
                        {department.company_name}
                      </h1>
                      <div
                        className="d-flex"
                        style={{
                          justifyContent: "space-between",
                          alignItems: "center",
                          columnGap: "20px",
                          marginTop: "2rem",
                        }}
                      >
                        <div>
                          {department.company_icon && (
                            <img
                              style={{
                                width: "2rem",
                              }}
                              src={
                                require(`../../Images/${department.company_icon}`)
                                  .default
                              }
                              alt={`${department.company_name} icon`}
                            />
                          )}
                        </div>
                        <div
                          style={{
                            color: "black",
                            fontSize: "clamp(0.6rem, 0.9rem, 1rem)",
                            flex: "2 1 0%",
                          }}
                        >
                          {wrapText(department?.company_select_text, 20).map(
                            (line, index) => (
                              <React.Fragment key={index}>
                                {line}
                                <br />
                              </React.Fragment>
                            )
                          )}
                        </div>
                        <div>
                          <i
                            className="fas fa-arrow-right"
                            style={{ color: "#A9A9A9" }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepartmentSelect;
