import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MTable from "../../Components/MTable/MTable";
import { getAll } from "../../Service/IncidentService";
import Swal from "sweetalert2";
import Overlay from "../../Components/Overlay";
import moment from "moment";
import ActionButton from "../../Components/MTable/ActionButton";
import StatusBadge from "../../Components/MTable/StatusBadge";
import "moment/locale/id";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isObjectEmpty } from "../../Utils/Utils";
import { capitalize } from "lodash";
import { getTicketReporting } from "../../Service/DashboardService";
import { InputSwitch } from "primereact/inputswitch";
import store from "../../Redux/Store/Store";

const { $ } = window;
const MyTickets = () => {
  let { userInfo } = useSelector((state) => state.auth);
  let { incident_search, incident_filter } = useSelector((state) => state.app);
  const incident_fields = [
    "ticket_number",
    "subject",
    "description",
    "urgency",
    "impact",
    "priority",
    "agent_email",
    "agent_name",
    "status",
    "requester",
    "requester_email",
    "requested_for",
    "requested_for_email",
  ];
  const [selected_export_fields, setSelectedExportFields] = useState(null);
  const [filter_from_date, setFilterFromDate] = useState(null);
  const [filter_to_date, setFilterToDate] = useState(null);
  const [filter_date_by, setFilterDateBy] = useState(null);
  const [select_fields_active, setSelectFieldsActive] = useState(false);
  moment.locale("id");
  const {
    formState: { errors },
  } = useForm({
    defaultValues: {
      company_name: "",
      company_address: "",
      mobile_number: "",
      phone_number: "",
      note: "",
      email: "",
      cp_name: "",
    },
  });
  const navigate = useNavigate();
  const tableSO = useRef();
  const [state, setState] = useState({ processing: false });

  const onAddData = () => {
    navigate("/incident-form");
  };

  const onEdit = (item) => () => {
    navigate("/salesorder/edit/" + item.id);
  };

  useEffect(() => {
    if (userInfo?.access) {
      if (userInfo?.access["my-tickets"]) {
        if (!userInfo?.access["my-tickets"]["can_view"]) {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "You're not allowed to access that page!",
          });
          if (userInfo?.role_name == "Requester") {
            navigate("/requester-landing");
          } else {
            navigate("/");
          }
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "You're not allowed to access that page!",
        });
        if (userInfo?.role_name == "Requester") {
          navigate("/requester-landing");
        } else {
          navigate("/");
        }
      }
    }
  }, []);

  const columns = [
    {
      id: 1,
      title: "Ticket Number",
      field: "ticket_number",
      sortable: true,
      style: { width: "13%" },
      render: (data) => {
        let text = data.ticket_number;
        return <b>{text}</b>;
      },
    },
    {
      id: 2,
      title: "Subject",
      field: "subject",
      sortable: true,
      style: { width: "11%" },
      render: (data) => {
        let text = data.subject;
        if (text && text.length > 30) {
          text = text.substring(0, 20) + "...";
        }
        return text;
      },
    },
    {
      id: 3,
      title: "Requested For",
      field: "requested_for",
      sortable: true,
      style:
        userInfo?.role_name == "Requester" ? { width: "9%" } : { width: "10%" },
      render: (data) => {
        if (data.requested_for_email) {
          let requester_email = `mailto: ${data.requested_for_email}`;

          return <a href={requester_email}>{data.requested_for}</a>;
        } else return <></>;
      },
    },
    {
      id: 13,
      title: "Category",
      field: "category_name",
      sortable: true,
      style: { width: "12%" },
    },
    {
      id: 4,
      title: "Requested Date",
      field: "requested_date",
      sortable: true,
      style: { width: "10%" },
      render: (data) => {
        return (
          moment(data.requested_date).format("DD MMM YYYY HH:mm") + " (WIB)"
        );
      },
    },

    {
      id: 7,
      title: "Agent",
      field: "agent_name",
      sortable: true,
      style: { width: "10%" },
    },
    {
      id: 99,
      title: "Group",
      field: "group_name",
      sortable: true,
      style: { width: "10%" },
    },
    {
      id: 5,
      title: "Status",
      field: "status",
      style: { width: "5%" },
      sortable: false,
      clickable: false,
      setAsSticky: false,
      render: (data) => {
        if (data.status == "Pending") {
          return (
            <StatusBadge
              bg_color={"b2b-badge-warning"}
              text={"Pending"}
              custom_width={"100%"}
            />
          );
        } else if (data.status == "Open") {
          return (
            <StatusBadge
              bg_color={"b2b-badge-info"}
              text={"Open"}
              custom_width={"80%"}
            />
          );
        } else if (data.status == "Resolved" || data.status == "Approved") {
          return (
            <StatusBadge bg_color={"b2b-badge-success"} text={data.status} />
          );
        } else if (data.status == "Closed") {
          return (
            <StatusBadge
              bg_color={"b2b-badge-secondary"}
              text={"Closed"}
              custom_width={"90%"}
            />
          );
        } else if (data.status == "Cancelled") {
          return (
            <StatusBadge
              bg_color={"b2b-badge-danger-alt"}
              text={"Cancelled"}
              custom_width={"80%"}
            />
          );
        }
      },
    },
    //{ id: 2, title: 'Priority', field: 'priority', sortable: true},
  ];

  const showAddButton = (role_name) => {
    if (!role_name || role_name == "Administrator") return false;
    else return true;
  };

  const tableGetData = (role_name) => {
    let filter = {
      filter: `is_deleted:false,company_id:${localStorage.getItem(
        "company_id"
      )},requester_email:${userInfo?.email}`,
    };
    let search = "";
    //if (incident_search) search = incident_search;
    return (params) => {
      /*if (incident_filter) {
          filter.filter += "," + incident_filter;
        } else {*/
      if (params.filter) {
        filter.filter += "," + params.filter;
      }
      //}
      /*store.dispatch({
          type: "INCIDENT_FILTER",
          payload: params.filter,
        });*/
      if (params.search) search = params.search;

      /*store.dispatch({
          type: "INCIDENT_SEARCH",
          payload: search,
        });*/
      return getAll({ ...params, ...filter, search });
    };
  };

  const genTableColumns = (role_name) => {
    if (role_name !== "Requester") {
      columns.push(
        /*{ id: 3, title: 'Requester', field: 'requester', sortable: true, style:{width:"7%"},
                render: data => {
                    let requester_email = `mailto: ${data.requester_email}`;
                    return <a href={requester_email}>{data.requester}</a>
                }
                },*/
        /*{
                    id: 8,
                    title: 'Urgency',
                    field: 'urgency',
                    sortable: true,
                    clickable: false,
                    style:{width:"10%"},
                    render: data => {
                        let color = "";
                        let icon = "";
                        if (data.urgency == 'Low') {
                            color = "#39E3A7";
                            icon = <FaThermometerQuarter/>;
                        } else if(data.urgency == "Medium"){
                            icon = <FaThermometerHalf/>
                            color= "#FFE900";
                        }
                        else if(data.urgency == "High"){
                            color = "red";
                            icon = <FaThermometerFull/>
                        }
                        else color = "#FF0000";
                        return <div style={{display:"flex", color, fontWeight:"700"}}>
                            <div>
                                {icon}
                            </div>
                            <div>{data.urgency}</div>
                        </div>;
                    },
                },*/
        {
          id: 10,
          title: "SLA Status",
          field: "sla_state",
          render: (item) => {
            if (item.status !== "Cancelled") {
              if (item.sla_state == "RUNNING")
                return <div style={{ color: "#FF0000" }}>Running</div>;
              else if (item.status == "Pending")
                return <div style={{ color: "#FFBF00" }}>Stopped</div>;
              else return <div style={{ color: "#FFBF00" }}>Stopped</div>;
            } else return <div style={{ color: "#FFBF00" }}>Stopped</div>;
          },
        },
        {
          id: 9,
          title: "Urgency",
          field: "urgency",
          style: { width: "8%" },
          sortable: true,
          clickable: false,
          render: (data) => {
            let color = "";
            let icon = "";
            if (data.urgency == "low") {
              color = "#39E3A7";
            } else if (data.urgency == "medium") {
              color = "#FFC300";
            } else if (data.urgency == "high") {
              color = "#FF7F50";
            } else {
              color = "#FF2400";
            }
            return (
              <div
                style={{
                  display: "flex",
                  color,
                  fontWeight: "700",
                  columnGap: "5px",
                }}
              >
                <div>
                  <div style={{ fontSize: "12px", paddingTop: "2px" }}>
                    <i className="fas fa-circle"></i>
                  </div>
                </div>
                <div>{capitalize(data.urgency)}</div>
              </div>
            );
          },
        },
        {
          id: 6,
          title: "Action",
          style: { width: "12%" },
          render: (item) => {
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                {/*<ActionButton icon={<MdOutlineModeEdit/>} link_color="#0099C3"/>*/}
                <ActionButton
                  href={`/incident/${item.id}`}
                  icon={
                    <i
                      className="fas fa-eye"
                      style={{ fontSize: "0.7rem" }}
                    ></i>
                  }
                  link_color="#0099C3"
                  text="View"
                />
                {/*<ActionButton icon={<BsTrashFill/>} link_color="#FF4833" click_action={(e) => onRemove(e, item.id)}/>*/}
              </div>
            );
          },
        }
      );
      return columns;
    } else {
      columns.push({
        id: 6,
        title: "Action",
        style: { width: "10%" },
        render: (item) => {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <ActionButton
                icon={
                  <i className="fas fa-eye" style={{ fontSize: "0.7rem" }}></i>
                }
                link_color="#0099C3"
                click_action={(e) => {
                  navigate(`/incident/${item.id}`);
                }}
                text="View"
              />
              {/*<ActionButton icon={<MdOutlineModeEdit/>} link_color="#0099C3"/>*/}
              {/*<ActionButton icon={<BsTrashFill/>} link_color="#FF4833" click_action={(e) => onRemove(e, item.id)}/>*/}
            </div>
          );
        },
      });
      return columns;
    }
  };

  const getTableWidth = (role_name) => {
    if (role_name !== "Requester") return "100%";
    else return "100%";
  };

  const isStickyEnd = (role_name) => {
    if (role_name !== "Requester") return true;
    else return false;
  };

  const onMapFilter = (filter) => {
    const result = [];
    if (!filter) return result;

    filter.split(",").forEach((item) => {
      const temp = item.split(":");
      let sample_obj = {};
      sample_obj["field"] = temp[0];
      sample_obj["value"] = temp[1];
      sample_obj["title"] = capitalize(temp[0].replace(/_/g, " "));
      result.push(sample_obj);
    });
    return result;
  };

  const propsTable = {
    columns: genTableColumns(userInfo?.role_name),
    getData: tableGetData(userInfo?.role_name),
    showIndex: false,
    showAddButton: showAddButton(userInfo?.role_name),
    addButtonText: "Incident",
    onAddData,
    order: "requested_date",
    direction: "desc",
    showCheckbox: true,
    minTableWidth: getTableWidth(userInfo?.role_name),
    stickyEnd: isStickyEnd(userInfo?.role_name),
    tableId: "table-incident",
    searchInit: incident_search,
    filterInit: onMapFilter(incident_filter),
    onSearchClear: () => {
      store.dispatch({ type: "INCIDENT_SEARCH", payload: "" });
    },
    onFilterClear: () => {
      store.dispatch({ type: "INCIDENT_FILTER", payload: "" });
    },
  };

  const getReport = () => {
    if (!filter_date_by) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select filter!",
      });

      return;
    }

    if (filter_date_by && !filter_from_date) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select from date!",
      });

      return;
    }

    if (filter_date_by && !filter_to_date) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select to date!",
      });

      return;
    }

    let args = {
      filter_from_date: moment(filter_from_date).format("YYYY-MM-DD"),
      filter_to_date: moment(filter_to_date).format("YYYY-MM-DD"),
      filter_date_by,
      type: "incident",
      company_id: localStorage.getItem("company_id"),
    };
    if (selected_export_fields && !isObjectEmpty(selected_export_fields)) {
      let result = [];
      Object.keys(selected_export_fields).forEach((item) => {
        result.push(item);
      });

      args.selected_fields = result;
    }
    getTicketReporting(args).then((res) => {
      if (res.status == 200) {
        if (!res.data.file) {
          Swal.fire({
            icon: "info",
            title: "No report found",
            text: "Please pick another incident category and filter",
          });

          return;
        }
        const link = document.createElement("a");
        link.href = `${process.env.REACT_APP_IMAGE_URL}${process.env.REACT_APP_SFTP_REPORT_FOLDER}${res.data.file}`;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6" style={{ paddingLeft: "20px" }}>
              <h2 className="title-breadcrum fw-500">My Tickets</h2>
              <h6>List of My Tickets</h6>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="card shadow mb-4">
            <div className="card-body">
              <Overlay display={state.processing} />
              <div>
                <MTable {...propsTable} ref={tableSO} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MyTickets;
